/* #region Philosopher */
@font-face {
	font-family: "Philosopher";
	src: local("Philosopher"),
	url(./philosopher/Philosopher-Regular.ttf) format("truetype");
}
@font-face {
	font-family: "Philosopher";
	src: local("Philosopher"),
	url(./philosopher/Philosopher-Bold.ttf) format("truetype");
	font-weight: 700;
}
@font-face {
	font-family: "Philosopher";
	src: local("Philosopher"),
	url(./philosopher/Philosopher-Italic.ttf) format("truetype");
	font-style: italic;
}
@font-face {
	font-family: "Philosopher";
	src: local("Philosopher"),
	url(./philosopher/Philosopher-BoldItalic.ttf) format("truetype");
	font-weight: 700;
	font-style: italic;
}
/* #endregion */
/* #region Montserrat */
@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"),
	url(./montserrat/Montserrat-Thin.ttf) format("truetype");
	font-weight: 100;
}
@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"),
	url(./montserrat/Montserrat-ThinItalic.ttf) format("truetype");
	font-style: italic;
	font-weight: 100;
}
@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"),
	url(./montserrat/Montserrat-ExtraLight.ttf) format("truetype");
	font-weight: 200;
}
@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"),
	url(./montserrat/Montserrat-ExtraLightItalic.ttf) format("truetype");
	font-style: italic;
	font-weight: 200;
}
@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"),
	url(./montserrat/Montserrat-Light.ttf) format("truetype");
	font-weight: 300;
}
@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"),
	url(./montserrat/Montserrat-LightItalic.ttf) format("truetype");
	font-style: italic;
	font-weight: 300;
}
@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"),
	url(./montserrat/Montserrat-Regular.ttf) format("truetype");
	font-weight: 400;
}
@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"),
	url(./montserrat/Montserrat-Italic.ttf) format("truetype");
	font-style: italic;
	font-weight: 400;
}
@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"),
	url(./montserrat/Montserrat-Medium.ttf) format("truetype");
	font-weight: 500;
}
@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"),
	url(./montserrat/Montserrat-MediumItalic.ttf) format("truetype");
	font-style: italic;
	font-weight: 500;
}
@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"),
	url(./montserrat/Montserrat-SemiBold.ttf) format("truetype");
	font-weight: 600;
}
@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"),
	url(./montserrat/Montserrat-SemiBoldItalic.ttf) format("truetype");
	font-style: italic;
	font-weight: 600;
}
@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"),
	url(./montserrat/Montserrat-Bold.ttf) format("truetype");
	font-weight: 700;
}
@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"),
	url(./montserrat/Montserrat-BoldItalic.ttf) format("truetype");
	font-style: italic;
	font-weight: 700;
}
@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"),
	url(./montserrat/Montserrat-ExtraBold.ttf) format("truetype");
	font-weight: 800;
}
@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"),
	url(./montserrat/Montserrat-ExtraBoldItalic.ttf) format("truetype");
	font-style: italic;
	font-weight: 800;
}
@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"),
	url(./montserrat/Montserrat-Black.ttf) format("truetype");
	font-weight: 900;
}
@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"),
	url(./montserrat/Montserrat-BlackItalic.ttf) format("truetype");
	font-style: italic;
	font-weight: 900;
}
/* #endregion */
html { font-family: 'Montserrat'; }
